<template>

  <div class="table-container">

    <Loading 
      v-if="loading"
      pixeles="150px"
    />

    <template v-if="!loading">  
      <table class="table table-custom table-borderless table-custom-responsive mb-0">
          <thead>
            <tr>
              <th scope="col">Tramo</th>
              <th scope="col">Tipo de transporte</th>
              <th scope="col">Transporte</th>
              <th scope="col" class="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody v-show="tramos.length != 0">
            <tr v-for="(tramo, i) in tramos" :key = "i" >
              <!-- tramo -->
              <td class="th th-tramo">
                {{ tramo.nombre_origen }} - {{ tramo.nombre_destino}}
              </td>
              <!-- tipo transporte -->
              <td class="th th-tipo-transporte">
                {{ tramo.transporte_tipo_medio.transporte_tipo.nombre_transporte }}
              </td>
            <!-- transporte -->
              <td class="th th-transporte">
                {{ tramo.transporte_tipo_medio.nombre_medio}} 
                ({{  numberTransporte(tramo.transporte_tipo_medio.cantidad_asiento) }})
              </td>
              <!-- acciones -->
              <td class="th th-accion text-center">
                <div class="dropdown dropdown-custom d-flex justify-content-center">
                  <button 
                    class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                    type="button"
                    data-bs-toggle="dropdown">
                    <font-awesome-icon icon="ellipsis-vertical"/>
                  </button>
                  <ul
                    class="dropdown-menu"
                  >
                    <li>
                      <a 
                        @click="openEditModal(tramo)"
                        data-bs-toggle="modal" 
                        data-bs-target="#modal-form-tramo"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                        Editar
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="deleteTramo(tramo)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                        Eliminar
                      </a>
                    </li>
                  </ul>
                </div>
  
  
              </td>
            </tr>
          </tbody>
      </table>
        <div class="transporte-container__no-data" v-show="tramos.length==0">
          <img
            class="transporte-container__img-question"
            src="@/assets/img/utilities/question_face.svg"
          />
          <p>Aún no se ha registrado ningún tramo</p>
          <button
            class="tramos-add__boton"
            data-bs-toggle="modal" 
            data-bs-target="#modal-form-tramo"
            @click="openCreateModal()"
          >
          + Crear tramo</button>
        </div>
        <Question
          v-if="open_question_modal"
          :msg="question_modal_msg"
          :hideCancel="false"
          @cancel="cancelQuestion"
          @accept="acceptQuestion"
        />
        <Status
          v-if="open_modal_status"
          :msg="modal_status_msg"
          :status="modal_status"
          @close="acceptStatus"
        />
    </template>
  </div>

</template>

<script>
  import { mapActions } from "vuex";
  import Question from "../Modales/Question.vue";
  import Status from "../Modales/Status.vue";

  //FF
  import Loading from "@/components/Loading.vue";

  export default {

    components: {
      Question, 
      Status,

      //FF
      Loading
    },

    props: {
      tramos: {
        type: Array,
      },
      loading: {
        type: Boolean,
        required: false
      }
    },

    data(){
      return{
        show_no_result: false,
        id_delete: '',
        open_question_modal: false,
        question_modal_msg: '',
        modal_status_msg: "",
        modal_status: false,
        open_modal_status: false,
      };
    },

    watch:{
      tramos() {
        if(this.tramos.length > 0) this.show_no_result = false;
        else this.show_no_result = true;
      }
    },

    methods: {
      ...mapActions("transporteModule", ["deleteTramoAction", "setEditingTramoAction"]),

      openCreateModal() {
        this.$emit("open-modal-create");
      },

      numberTransporte(num){
        if (num.length == 1) num = '0'+num;
        return num;
      },

      openEditModal(tramo) {
        this.setEditingTramoAction(tramo);
        this.$emit("open-modal-edit");
      },

      // Eliminar tramo pregunta
      deleteTramo(tramo) {
        this.id_delete = tramo.id_destino;
        this.open_question_modal = true;
        this.question_modal_msg = "¿Estás seguro de eliminar este tramo?";
      },

      // Eliminar tramo
      async acceptQuestion() {
        const payload = {
          idDestino: this.id_delete,
          idEmpresa: this.$ls.get("user").id_empresa_fk,
          idUsuario: this.$ls.get("user").id_usuario,
        };
        const deletedTramo = await this.deleteTramoAction(payload);
        this.open_question_modal = false;
        this.modal_status = false;
        if (deletedTramo) {        
          if (deletedTramo[0].nombre_destino == 'in_planificacion') {
            this.open_modal_status = true;
            this.modal_status_msg = 'No se pueden eliminar tramos que estén siendo usados en planificaciones.';
          } else {
            this.toastTramoDeleteSuccess();
          }
        } else {        
          this.open_modal_status = true;
          this.modal_status_msg = "Ha ocurrido un problema en la eliminación del tramo. Por favor inténtalo nuevamente.";
        }
      },
      
      // Cancela eliminar
      cancelQuestion() {
        this.open_question_modal = false;
      },

      // Termina eliminar
      acceptStatus(){
        this.open_modal_status = false;
      },

      toastTramoDeleteSuccess() {
        this.$toast.open({
          message: 'Tu tramo ha sido eliminado correctamente.',
          type: 'success',
          duration: 6000,
          position: 'top-right'
        });
      }

    },
  };
</script>

