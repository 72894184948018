<template>

  <section id="mis-tramos">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          En esta sección podrás crear y visualizar los tramos que necesites para tus planificaciones
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <button 
          v-if="tramosArr.length > 0"
          @click="open_modal = true"
          data-bs-toggle="modal" 
          data-bs-target="#modal-form-tramo"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear tramo
      </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <MisTramosTableVue
          :tramos="tramosArr"
          @open-modal-create="open_modal = true"
          @open-modal-edit="onOpenModalForEdit"
          :loading="loading"
        />
      </div>
    </div>

    <div class="users-list__pagination" v-if="this.pagination.page_count > 1">
      <paginate
        v-model="pagination.actual_page"
        :page-count="pagination.page_count"
        :page-range="pagination.page_range"
        :click-handler="pagination.click_handler"
        :prev-text="pagination.prev_text"
        :next-text="pagination.next_text"
        :container-class="pagination.container_class"
        :page-class="pagination.page_class"
      />
    </div>

<!-- Modal -->
<div 
  class="modal modal-custom fade" 
  id="modal-form-tramo" 
  tabindex="-1" 
>
  <div class="modal-dialog">
    <div class="modal-content">
      <CrearTramo 
        v-if="open_modal === true"
        @userInputChange="onUserInputChange"
        @close-modal="closeModal()" 
      />
      <div class="modal-footer">
        <button 
          type="button" 
          ref="closeModal"
          class="btn btn-custom-color-white rounded-pill mw-100" 
          @click="closeModal()"
          data-bs-dismiss="modal">
          <i class="fa-solid fa-angle-left pe-1"></i>
          Volver
        </button>
        <button 
          type="button" 
          class="btn btn-custom-color-blue rounded-pill mw-100"
          @click="addOrEdit()">
          {{nameAction()}}
        </button>
      </div>
    </div>
  </div>
</div>
  
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="acceptStatus"
    />

  </section>

</template>

<script>
  import CrearTramo from "./CrearTramo.vue";
  import MisTramosTableVue from "./MisTramosTable.vue";
  import { mapActions, mapState } from "vuex";
  import Status from "../Modales/Status.vue";

  export default {
    components: {
      CrearTramo,
      MisTramosTableVue,
      Status
    },

    data() {
      return {
        open_modal: false,
        editBool: false,
        userInput: {},
        modal_status_msg: "",
        modal_status: false,
        open_modal_status: false,
        pagination: {
          actual_page: 1,
          page_count: 0,
          page_range: 3,
          click_handler: this.changePage,
          prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
          next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
          container_class: "users-list__pagination-container",
          page_class:"pagination-item"
        },
        tramos_limit: "15",

        //FF
        loading: true
      };
    },

    computed: {
      ...mapState("transporteModule", ["tramos_list", "tramosPaginationArr", "tramosArr"]),
    },

    mounted() {
      this.searchTramos();
    },

    methods: {
      ...mapActions("transporteModule", [
        "createTramoAction",
        "editTramoAction",
        "cleanEditingTramoAction",
        "getTramosEmpresa",
        "getTramosPagination",
        "getTramosPaginated"
      ]),

      async searchTramos(){
        this.loading = true;
        await this.getTramosPages();
        if(this.tramosPaginationArr.length > 0){
          this.pagination.actual_page = 1;
          await this.getTramosByPage(this.pagination.actual_page);
        }
        this.loading = false;
      },

      async getTramosPages() {
        const data_ = {
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          limite: this.tramos_limit,
        }
        await this.getTramosPagination(data_);
        this.pagination.page_count = this.tramosPaginationArr.length;
      },

      async getTramosByPage(page) {
        this.loading = true;
        let data_ = {
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          id_tramo: this.tramosPaginationArr[(page -1)].tramos,
        }
        await this.getTramosPaginated(data_);
        this.loading = false;
      },

      async changePage(page) {
        this.loading = true;
        this.pagination.actual_page = page;
        await this.getTramosByPage(this.pagination.actual_page);
        this.loading = false;
      },

      async addOrEdit() {
        if (this.validateInputs() == true) {
          if (this.editBool) {
            await this.editTramo();
            this.$refs.closeModal.click();
          }
          else {
            await this.addTramo();
            this.$refs.closeModal.click();
          }
        } else {
          this.toastTramoValidationIncomplete();
        }
      },

      nameAction() {
        if (this.editBool) return 'Guardar';
        else return 'Añadir';
      },

      async addTramo() {
        const payload = {
          idEmpresa: this.$ls.get("user").id_empresa_fk,
          idUsuario: this.$ls.get("user").id_usuario,
          idMedio: this.userInput.idMedio.value,
          idaVuelta: this.userInput.idaVuelta.value,
          nombreOrigen: this.userInput.nombreOrigen.value,
          nombreDestino: this.userInput.nombreDestino.value,
          idDestinoPadre: "0",
          isConexion: false,
          antesDespues: "0"
        };
        this.loading = true;
        const newTramo = await this.createTramoAction(payload);
        this.loading = false;
        this.open_modal = false;
        if (newTramo) {
          this.clean();
          this.toastTramoCreateSuccess(); 
        } else {
          this.modal_status = false;
          this.modal_status_msg = "Ha ocurrido un problema en la creación del tramo. Por favor inténtalo de nuevo.";
          this.open_modal_status = true;
        }
      },

      async editTramo() {
        const payload = {
          idEmpresa: this.$ls.get("user").id_empresa_fk,
          idUsuario: this.$ls.get("user").id_usuario,
          idMedio: this.userInput.idMedio.value,
          idaVuelta: this.userInput.idaVuelta.value,
          nombreOrigen: this.userInput.nombreOrigen.value,
          nombreDestino: this.userInput.nombreDestino.value,
          idDestino: this.userInput.idDestino.value,
          isConexion: this.userInput.isConexion.value,
          idDestinoPadre: "0",
          antesDespues: "0"
        };
        this.loading = true;
        const editedTramo = await this.editTramoAction(payload);
        this.loading = false;
        this.open_modal = false;
        if (editedTramo) {        
          this.toastTramoEditSuccess();
        } else {        
          this.modal_status = false;
          this.modal_status_msg = "Ha ocurrido un problema en la edición del tramo. Por favor inténtalo de nuevo.";
          this.open_modal_status = true;
        }
      },

      acceptStatus() {
        this.open_modal_status = false;
        this.modal_status_msg = "";
      },

      clean() {
        this.cleanEditingTramoAction();
        this.editBool = false;
        this.userInput = {};
      },

      onUserInputChange(userInputObj) {
        this.userInput = userInputObj;
      },

      closeModal() {
        this.clean();
        this.open_modal = false;
      },

      validateInputs() {      
        ["nombreOrigen", "nombreDestino", "idMedio", "idaVuelta", "tipo"].forEach(field => {
          this.userInput[field].error = this.userInput[field].value === '' ? true : false;
        });

        if (Object.values(this.userInput).some(val => val.error)) {
          return false;
        } else {
          return true;
        }
      },

      onOpenModalForEdit() {
        this.editBool = true;
        this.open_modal = true;
      },

      toastTramoCreateSuccess() {
        this.$toast.open({
          message: 'Tu tramo ha sido creado correctamente.',
          type: 'success',
          duration: 6000,
          position: 'top-right'
        });  
      },
      toastTramoEditSuccess() {
        this.$toast.open({
          message: 'Tu tramo ha sido actualizado correctamente.',
          type: 'success',
          duration: 6000,
          position: 'top-right'
        });
      },
      toastTramoValidationIncomplete() {
        this.$toast.open({
          message: 'Por favor completa los campos obligatorios.',
          type: 'error',
          duration: 5000,
          position: 'top-right'
        });
      }
    },
  };
</script>
